<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item label="支付方式：" :inline-message="true" prop="paymentType">
						<el-radio v-model="ruleForm.paymentType" label="wx">微信支付</el-radio>
						<el-radio v-model="ruleForm.paymentType" label="W06">收银宝</el-radio>
						<el-radio v-model="ruleForm.paymentType" label="cloud">第三方支付</el-radio>
					</el-form-item>

					<!-- <el-form-item
						label="支付商户号："
						:inline-message="true"
						prop="paymentToAccount"
						:rules="[{required:true,message:'支付商户号不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.paymentToAccount" style="width: 300px;" maxlength="20"></el-input>
					</el-form-item>

					<el-form-item
						label="支付密钥："
						:inline-message="true"
						prop="paymentSecret"
						:rules="[{required:true,message:'支付密钥不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.paymentSecret" style="width: 300px;" maxlength="20"></el-input>
					</el-form-item>-->
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
import { getPaymentSetting, savePaymentSetting } from "@/api/setting";
export default {
	data () {
		return {
			ruleForm: {
				paymentType: 'wx',    //支付方式，wx=微信支付，cloud=第三方支付
			},
			loading: false,  //保存接口防止多次点击
		};
	},
	computed: {

	},
	created () {
		this.getPaymentSetting();
	},

	methods: {
		//获取详细数据
		async getPaymentSetting () {
			let res = await getPaymentSetting();
			this.ruleForm = {
				paymentType: res.data.paymentType,    //支付方式，1=微信支付，2=第三方支付
			}

		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
			this.loading = true
			let res = await savePaymentSetting(this.ruleForm);

			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}else{
                this.$message({
					type: 'error',
					message: res.alertMsg
				});
            }
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getPaymentSetting();
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
</style>
