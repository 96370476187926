import request from '@/utils/request'

/**
 * 设置模块接口
 */

//获取售后设置
export const getAfterSaleSetting = (data) => request('/admin/sysConfig/getAfterSaleSetting', data, 'get');
//保存售后设置
export const saveAfterSaleSetting = (data) => request('/admin/sysConfig/saveAfterSaleSetting', data);

//获取商城设置
export const getMallSetting = (data) => request('/admin/sysConfig/getMallSetting', data, 'get');
//保存商城设置
export const saveMallSetting = (data) => request('/admin/sysConfig/saveMallSetting', data);

//获取支付设置
export const getPaymentSetting = (data) => request('/admin/sysConfig/getPaymentSetting', data, 'get');
//保存支付设置
export const savePaymentSetting = (data) => request('/admin/sysConfig/savePaymentSetting', data);

//获取短信设置
export const getSmsSetting = (data) => request('/admin/sysConfig/getSmsSetting', data, 'get');
//保存短信设置
export const saveSmsSetting = (data) => request('/admin/sysConfig/saveSmsSetting', data);

//获取交易设置
export const getTransactionSetting = (data) => request('/admin/sysConfig/getTransactionSetting', data, 'get');
//保存交易设置
export const saveTransactionSetting = (data) => request('/admin/sysConfig/saveTransactionSetting', data);

//获取其他设置
export const getOtherSetting = (data) => request('/admin/sysConfig/getOtherSetting', data, 'get');
//保存其他设置
export const saveOtherSetting = (data) => request('/admin/sysConfig/saveOtherSetting', data);

//操作日志
export const getOperationLog = (data) => request('/admin/backOperateLog/pagedList', data);
//物流公司
export const companyList = (data) => request('/admin/logistics/company/list', data);

//合作中心
export const centrefindAll = (data) => request('/admin/centre/findAll', data);

//去联系
export const updateStatus = (data) => request('/admin/centre/updateStatus', data);
 
/**
 * 服务协议
 */
//获取服务承诺列表
export const getSericeList = (data) => request('/admin/serice/findPage',data);
//添加服务承诺
export const addSerice = (data) => request('/admin/serice/add',data);
//编辑服务承诺
export const updateSerice = (data) => request('/admin/serice/update',data);
//删除服务承诺
export const delSerice = (data) => request('/admin/serice/del',data);


/**
 * 奖励配置**/

//获取奖励配置设置
export const getAwardConfigSetting = (data) => request('/admin/sysConfig/getAwardConfigSetting', data, 'get');
//保存奖励配置设置
export const saveAwardConfigSetting = (data) => request('/admin/sysConfig/saveAwardConfigSetting', data);


//公告相关
//添加公告
export const addNotice = (data) => request('/admin/notice/addNotice',data);
//删除服务承诺
export const delNotice = (data) => request('/admin/notice/del',data);
//根据id查询公告
export const findNotice = (data) => request('/admin/notice/findNotice',data);
//列表查询
export const findNoticeList = (data) => request('/admin/notice/findPage',data);
//修改公告
export const updateNotice = (data) => request('/admin/notice/updateNotice',data);
//发布公告
export const updateNoticeStatus = (data) => request('/admin/notice/updateStatus',data);

// 版本管理
//添加版本
export const addAppVersion = (data) => request('/admin/app/addAppVersion',data);
//删除版本
export const delAppVersion = (data) => request('/admin/app/delAppVersion',data);
//列表查询
export const findPageAppVersion = (data) => request('/admin/app/findPageAppVersion',data);
//修改版本
export const updateAppVersion = (data) => request('/admin/app/updateAppVersion',data);